@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "cropperjs/dist/cropper.css";
@import "quill/dist/quill.core.css";
@import "@algolia/autocomplete-theme-classic/dist/theme.css";
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700');

:root {
  --aa-primary-color-rgb: 2, 47, 76;
  --aa-selected-color-rgb: 245, 244, 240;
  --aa-selected-color-alpha: 1;
}

html, body {
  min-height: 100%;
  height: 100%;
  font-family: 'Soehne';
}

@media screen and (max-width: 640px) {
  html, body {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .min-h-screen {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .max-h-screen {
    max-height: calc(var(--vh, 1vh) * 100);
  }

  .space-first-ml-4 {
    > :first-of-type {
      @apply ml-4;
    }
  }
}

.max-w-narrow {
  max-width: 40ch;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Soehne';
  src: url('fonts/soehne-buch.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Soehne';
  src: url('fonts/soehne-buch-kursiv.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Soehne';
  src: url('fonts/soehne-kraftig.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Soehne';
  src: url('fonts/soehne-kraftig-kursiv.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

.admin-modal-box {
  @apply my-auto;

  max-height: 90vh;
  min-height: 320px;
}

li.entity-option.active {
  @apply text-white;
  @apply bg-primary-900;
}

.turbo-progress-bar {
  &:before {
    content: '';
    background: #7387A1;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    width: 100vw;
  }

  &:after {
    @apply bg-primary-700;
    content: '';
    width: 100%;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 6px;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.is-spinning {
  animation: spinner 2s linear infinite;
}

.is-delayed {
  animation-delay: 0.2s;
}

button, a {
  &.is-loading {
    @apply text-transparent;
    @apply relative;

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border-right: 2px solid transparent;
      animation: spinner .6s linear infinite;

      // Set border-top with tailwind
      @apply border-t-2;
    }
  }
}

[type='text']:focus, [type='email']:focus, [type='url']:focus,
[type='password']:focus, [type='number']:focus, [type='date']:focus,
[type='file']:focus, [type='datetime-local']:focus, [type='month']:focus,
[type='search']:focus, [type='tel']:focus, [type='time']:focus,
[type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  @apply ring-primary-900;
  @apply border-primary-900;
}

[type='file']:focus {
  @apply outline-primary-900;
}

button:focus-visible {
  @apply outline-fawn-900;
}

turbo-frame[id="admin-sidebar"] {
  display: block;
  min-height: 100%;
}

turbo-frame[id="admin-sidebar"] > div {
  min-height: 100%;
}

.ql-editor, .rrt {
  @apply font-sans;
  @apply text-p4;

  a, a:visited {
    @apply text-indicator-blue;
    @apply underline;
  }

  ol, ul {
    padding: 0;

    li {
      padding-left: 1.5em;
      list-style-type: none;
      overflow-wrap: break-word;

      &:before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }
    }
  }

  ol > li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;

    &:before {
      content: counter(list-0, decimal) '. ';
    }
  }

  ul > li:before {
    content: '\2022';
  }

  blockquote {
    @apply font-semibold;
    @apply italic;

    border-left-width: 0.25rem;
    color: var(--tw-prose-quotes);
    border-left-color: var(--tw-prose-quote-borders);
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 0.25em;
    margin-bottom: 0.25rem;
    padding-left: 1em;
  }

  h4 {
    @apply text-h4;
  }
}

.ql-editor, .rrtv2 {
  //@apply font-sans-v2;
  //@apply text-p3-v2;

  a, a:visited {
    @apply text-indicator-blue;
    @apply underline;
  }

  ol, ul {
    padding: 0;

    li {
      padding-left: 1.5em;
      list-style-type: none;
      overflow-wrap: break-word;

      &:before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }
    }
  }

  ol > li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;

    &:before {
      content: counter(list-0, decimal) '. ';
    }
  }

  ul > li:before {
    content: '\2022';
  }

  blockquote {
    @apply font-semibold;
    @apply italic;

    border-left-width: 0.25rem;
    color: var(--tw-prose-quotes);
    border-left-color: var(--tw-prose-quote-borders);
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 0.25em;
    margin-bottom: 0.25rem;
    padding-left: 1em;
  }

  h4 {
    @apply text-h4;
  }
}

svg .ql-stroke {
  stroke: currentColor;

  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
}

button.ql-active {
  @apply bg-gray-800;
  @apply text-primary-700;
  @apply transition-all;
}
